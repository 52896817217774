import React, {useEffect, useRef, useState} from "react";
import {Html5Qrcode} from "html5-qrcode";
import {Button, IconButton, Stack, Typography} from "@mui/material";
import {QrCodeScanner} from "@mui/icons-material";

const brConfig = {fps: 10, qrbox: {width: 300, height: 150}};
let html5QrCode;

// function startCamera(){}

const Scanner = (props) => {
    const [scanning, setScanning] = useState(false);

    const [cameraList, setCameraList] = useState([]);
    const [activeCamera, setActiveCamera] = useState();
    useEffect(() => {
        html5QrCode = new Html5Qrcode("reader");
        getCameras();
        const oldRegion = document.getElementById("qr-shaded-region");
        oldRegion && oldRegion.remove();
    }, []);

    const handleClickAdvanced = () => {
        setScanning(true);

        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            console.info(decodedResult, decodedText);
            props.onResult(decodedText);
            alert(`decoded:__ ${decodedText}`);
            handleStop();
        };
        html5QrCode
            .start(
                {facingMode: "environment"},
                brConfig,
                qrCodeSuccessCallback
            )
            .then(() => {
                // const oldRegion = document.getElementById("qr-shaded-region");
                // if (oldRegion) oldRegion.innerHTML = "";
            });
    };
    const getCameras = () => {
        Html5Qrcode.getCameras()
            .then((devices) => {
                /**
                 * devices would be an array of objects of type:
                 * { id: "id", label: "label" }
                 */
                console.info(devices);
                if (devices && devices.length) {
                    setCameraList(devices);
                    setActiveCamera(devices[0]);
                }
            })
            .catch((err) => {
                console.error(err);
                setCameraList([]);
            });
    };
    const onCameraChange = (e) => {
        if (e.target.selectedIndex) {
            let selectedCamera = e.target.options[e.target.selectedIndex];
            console.info(selectedCamera);
            let cameraId = selectedCamera.dataset.key;
            setActiveCamera(cameraList.find((cam) => cam.id === cameraId));
        }
    };
    const handleStop = () => {
        setScanning(false);
        try {
            html5QrCode
                .stop()
                .then((res) => {
                    html5QrCode.clear();
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div id={"scanner"}
             style={{
                 position: "relative",
             }}>

            <div id="reader" width="100%"></div>
            <Button
                startIcon={<QrCodeScanner/>}
                variant="contained"
                fullWidth={true}
                sx={{
                    marginTop: 2
                }}
                onClick={scanning ? handleStop : handleClickAdvanced}
            >
                {scanning ? 'Cerrar cámara' : 'Leer código Pedido / Línea'}
            </Button>
        </div>
    );
};

export default Scanner;
