import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { CameraAlt } from "@mui/icons-material";
import {Button, Grid} from "@mui/material";

const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
};

const CameraCapture = () => {
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState([]);

    const capture = useCallback(() => {
        console.log("capturando foto");
        const newImage = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc => [...imageSrc, newImage])
    }, [webcamRef, setImgSrc]);

    return (
        <>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                minScreenshotWidth={180}
                minScreenshotHeight={180}
            />
            <Button
                startIcon={<CameraAlt/>}
                variant="contained"
                fullWidth={true}
                sx={{
                    marginTop: 2
                }}
                onClick={capture}
            >
                Hacer foto
            </Button>
            <Grid container spacing={2}>
                {imgSrc.map(snapshotSrc => (
                    <Grid item>
                        <img src={snapshotSrc} alt="img" />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default CameraCapture;
