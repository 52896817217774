import './App.css';
import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Stack, Step, StepContent, StepLabel, Stepper,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Scanner from "./components/HTML5Qrcode/Scanner";

import Webcam from "react-webcam";
import CameraCapture from "./components/CameraCapture";


function App(props) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [decodedValue, setDecodedValue] = useState("");

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ maxWidth: 400 }} alignItems="center">
            <Typography
                sx={{
                    marginBottom: 10
                }}
                variant="h4"
                component="h1"
                gutterBottom
                textAlign={"center"}>
                KITZ Parcel Snapshot
            </Typography>
                <Stepper activeStep={activeStep} alternativeLabel
                         orientation={"vertical"}>
                    <Step key={"step1"}>
                        <StepLabel>Scan Barcode</StepLabel>
                        <StepContent>
                            <Box
                                sx={{my: 4}}
                            >
                                <Scanner
                                    onResult={(res) => setDecodedValue(res)}/>
                                <Typography
                                    sx={{
                                        marginTop: 10
                                    }}
                                    variant="h4"
                                    component="h1"
                                    gutterBottom
                                    textAlign={"center"}>
                                    No. {decodedValue ? decodedValue : "###### / ####"}
                                </Typography>
                            </Box>
                            <Box sx={{mb: 2, my: 8}}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Siguiente
                                    </Button>
                                    <Button
                                        disabled={true}
                                        onClick={handleBack}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Atrás
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key={"step2"}>
                        <StepLabel>Agregar imágenes</StepLabel>
                        <StepContent>
                            <Container maxWidth="sm">
                                <hr/>
                                <Container
                                    sx={{
                                        marginTop: 10
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginBottom: 10
                                        }}
                                        variant="h4"
                                        component="h1"
                                        gutterBottom
                                        textAlign={"center"}>
                                        Fotos del Pedido / Línea:
                                    </Typography>

                                    <CameraCapture/>
                                </Container>
                            </Container>
                            <Box sx={{mb: 2, my: 8}}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Siguiente
                                    </Button>
                                    <Button
                                        disabled={false}
                                        onClick={handleBack}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Atrás
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Enviar imágenes</StepLabel>
                        <StepContent>
                            Patata
                            <Box sx={{mb: 2, my: 8}}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Enviar Imágenes
                                    </Button>
                                    <Button
                                        disabled={false}
                                        onClick={handleBack}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Atrás
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === 3 && (
                    <Paper square elevation={0} sx={{p: 3}}>
                        <Typography>Todos los pasos completados - Pedido terminado</Typography>
                        <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                            Empezar otro pedido
                        </Button>
                    </Paper>
                )}
        </Box>
    )
        ;
}

export default App;
